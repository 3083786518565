$input-padding: $padding-rythm-sm / 2;
$input-main-color: grey;

.form-select {
	position: relative;
	width: 100%;

	// Fallback styles for touch devices
	&--field {
		display: block;
		padding: $input-padding $input-padding $input-padding $input-padding;
		width: 100%;

		font-size: $fs-body;
		line-height: 1.3;
		font-weight: 100;

		background: none;

		border-radius: 0;
		border: 1px solid;

		color: inherit;
		box-shadow: none;
		outline: none;

		&.is-valid {
		}

		&.is-invalid {
			border-color: red;
			~ .form-select--error-messages {
				display: block;
			}
		}

		&:invalid {
			// Overide browser defaults. We use classes as below (for browser compat and UX reasons)
			outline: 0 none;
			box-shadow: none;
		}
		&:required {
		}
	}

	@import "../vendor/choices";

	&--error-messages {
		width: 100%;
		&-single {
			margin-top: 12px;
		}
	}

	&--help {
		width: 100%;
		padding: 12px;
	}
}
