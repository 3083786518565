.l-textual-layout {
	&--title {
		@include base-heading;
		color: var(--text-accent);
		font-size: $fs-h2-sm;
	}
	&--col {
		&-title {
			@include base-heading;
			color: var(--text-accent);
			font-size: $fs-h3-sm;
			margin-bottom: 5px;
		}
		&-subtitle {
			@include base-heading;
			color: var(--text-accent);
			margin-bottom: $padding-rythm-sm;
		}
		&-copy {
			margin-bottom: $padding-rythm-sm;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&-ctas {
			margin-top: $padding-rythm / 2;
		}
		&-arrow {
			padding-bottom: 4px;
			padding-left: 4px;
			&:before {
				content: "";
				width: 20px;
				height: 20px;
				border-top: 1px solid var(--text-accent);
				border-right: 1px solid var(--text-accent);
				transform: rotate(135deg);
				display: block;
			}
		}
	}
}

@include to($bp-medium) {
	.l-textual-layout {
		&--title,
		&--col {
			padding-left: $padding-rythm-sm;
			position: relative;

			&:before {
				content: "";
				width: 1px;
				height: 100%;
				background: black;
				background: var(--text-accent);
				opacity: 0.2;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		&--title {
			padding-bottom: $padding-rythm-sm; // Not using margin because of the line on the left -JS
		}

		&--col {
			overflow: hidden;
			&-arrow {
				margin: ($padding-rythm-sm * 2 - 11px) 0 $padding-rythm-sm * 2 0; // Adjusted for line-heights -JS
			}
			&-cover-image {
				margin-top: $padding-rythm-sm;
			}
			&:first-child {
				margin-bottom: $padding-rythm-lg;
			}
		}

		&__video-block &--col:first-child {
			margin-bottom: 0;
		}
		&__video-block &--col .video-embed {
			margin-top: $padding-rythm / 2;
		}
	}
}

@include from($bp-medium) {
	.l-textual-layout {
		$c-root: &;

		display: grid;
		grid-template-columns: repeat(5, 1fr);
		column-gap: $padding-rythm;

		&--title {
			grid-column: 1 / 3;

			font-size: $fs-h2;
		}
		&--inner {
			grid-column: 1 / 6;

			display: grid;
			grid-template-columns: repeat(5, 1fr);
			gap: $padding-rythm;

			padding-top: 22px; // Adjusted for line-height to match margin above &--copy -JS
			margin-top: 14px; // Adjusted for line-height to match margin above &--copy -JS

			// Not using border to avoid aliasing errors -JS
			position: relative;
			&:before {
				content: "";
				width: 100%;
				height: 1px;
				background: black;
				background: var(--text-accent);
				opacity: 0.2;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		&--col {
			position: relative;
			display: flex;
			flex-direction: column;

			&-title {
				font-size: 20px; // Custom font size to deal with them wanting "Creative director announced" -JS
				margin-top: -114px; // Align baseline with .l-intro-ctas--title -JS
				margin-bottom: 42px; // Adjusted for line-height and negative margion -JS

				min-height: 72px;
				display: flex;
				align-items: flex-end;
			}
			&-subtitle {
				margin-bottom: 1.1em;
			}
			&-copy {
				&:first-child {
					margin-top: -0.3em; // Adjust for line-height -JS
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			&-arrow {
				position: absolute;
				right: -#{$padding-rythm/2};
				top: -75px;
				&:before {
					width: 16px;
					height: 16px;
					transform: rotate(45deg);
				}
			}
			&-cover-image {
			}
			&:nth-child(1) {
				grid-column: 1 / 3;
			}
		}
		&__2-col {
			#{$c-root}--title {
				grid-column: 1/3;
			}
			#{$c-root}--inner {
				grid-column: 1 / 3;
				grid-template-columns: repeat(2, 1fr);
			}
		}
		&__video-block {
			#{$c-root}--title {
				grid-column: 1/5;
			}
			#{$c-root}--inner {
				grid-column: 1 / 5;
				grid-template-columns: repeat(4, 1fr);
			}

			#{$c-root}--col:nth-child(1) {
				grid-column: 1 / 3;
			}
			#{$c-root}--col:nth-child(2) {
				grid-column: 3 / 5;
			}
		}
	}
}

@include from($bp-large) {
	.l-textual-layout {
		$c-root: &;

		column-gap: $padding-rythm-lg;
		&--inner {
			column-gap: $padding-rythm-lg;
		}
		&--col {
			&-arrow {
				position: absolute;
				right: -#{$padding-rythm-lg/2};
			}
		}
		&__video-block {
			#{$c-root}--title {
				grid-column: 1/3;
			}
		}
	}
}
