.l-archive {
	&--grid {
		padding: $padding-rythm / 2;
		padding-top: $padding-rythm;
		grid-template-columns: repeat(5, 1fr);
		display: grid;
		flex-direction: column;
		background: var(--primary-dark);
	}

	&--column {
		grid-column: 1/6;

		&-title {
			position: relative;
			color: var(--text-accent);
			margin-bottom: $padding-rythm-sm;
			padding-bottom: $padding-rythm-sm / 1.5;

			&:after {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 1px;
				background: var(--text-light);
			}
		}

		&-body {
			color: var(--text-light);
		}
	}
}

@include from($bp-medium) {
	.l-archive {
		&--grid {
			padding: 50px;
		}

		&--column {
			grid-column: 1/3;
		}
	}
}

@include from($bp-large) {
	.l-archive {
		&--grid {
			padding: 100px;
		}

		&--column {
			grid-column: 1/4;
		}
	}
}
