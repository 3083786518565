.card {
	transition: background-color 200ms ease-in-out;
	display: block;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background: $midlight-grey;
	box-shadow: $box-shadow;

	&--image {
		width: 100%;
	}
	&--details {
		width: 100%;
		padding: $padding-rythm-sm;
		flex: 1;
	}

	&--title {
		font-size: $fs-h3-sm;
		margin-bottom: $padding-rythm-sm;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&--summary {
		margin-bottom: $padding-rythm-sm;
	}
	&--learn-more {
		@include base-ui-link;
		font-weight: bold;
		padding-bottom: 2px;
		display: inline-block;
		&:after {
			background: currentColor;
		}
		&:hover,
		&:active,
		&:focus {
			&:after {
				background: currentColor;
			}
		}
	}
	&:hover,
	&:active,
	&:focus {
		background: $light-grey;
	}
}

.card-group {
	&--title {
		font-size: $fs-h3-sm;
	}
}

@include to($bp-small) {
	.card-group {
		.card + .card {
			margin-top: $padding-rythm-sm;
		}
	}
}

@include from($bp-small) {
	.card {
		&--details {
			display: flex;
			flex-direction: column;
		}

		&--learn-more {
			margin-top: auto;
			align-self: flex-start;
		}
	}
	.card-group {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: $padding-rythm / 2 $padding-rythm;
		&--title {
			grid-column: 1 / 4;
		}
		&__small {
			grid-gap: $padding-rythm-sm;
		}
	}
}

@include from($bp-large) {
	.card {
		font-size: $fs-body-large;
		&--details {
			padding: 40px;
		}
		&--title {
			font-size: $fs-h3;
		}
		&__small {
			.card--details {
				padding: $padding-rythm-sm;
			}
			.card--title {
				font-size: $fs-body;
			}
		}
	}
	.card-group {
		&--title {
			font-size: $fs-h3;
		}
	}
}

@include from($bp-very-large) {
	.card-group {
		grid-gap: $padding-rythm / 2 $padding-rythm-lg;
		&__small {
			grid-gap: $padding-rythm-sm;
		}
	}
}
