.l-fold {
	height: 0;
	position: relative;
	overflow: hidden;

	padding-bottom: (640 / 375) * 100%;

	background: var(--primary-dark);

	&--inner {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		padding: $padding-rythm/2;
		padding-top: $padding-rythm-lg; //  #tempthemeremove Remove when theme is live -JS
		padding-top: 85px;

		display: flex;

		z-index: 3;
	}

	&--copy {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	&--logo {
		width: 65%;
	}
	&--supertitle {
		@include base-heading;
		// color: var(--text-accent); #tempthemeremove Put back in when theme is live -JS
		color: var(--text-light); //  #tempthemeremove Remove when theme is live -JS
		font-size: $fs-body;
		margin-bottom: 6px; // Adjusted for line-height -JS
	}
	&--title {
		color: var(--text-light);
		line-height: 1;
		margin-left: -0.08em; // Adjust for space around letters to line up with left hadn edge -JS
		// font-size: $fs-h1-sm;  #tempthemeremove Remove when theme is live -JS
		font-size: 55px;
		&-lower {
			// color: var(--text-accent); #tempthemeremove Put back in when theme is live -JS
		}
		&__black {
			color: var(--text-dark);
		}
	}
	&--subtitle {
		color: var(--text-light);
		font-size: $fs-body;
		margin-top: 15px;
		line-height: 1.5;
		&__black {
			color: var(--text-dark);
		}
	}
	&--image-bg,
	&--video-bg {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	&--image-bg {
		z-index: 1;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}
	&--video-bg {
		object-fit: cover;
		object-position: center;
		z-index: 2;
	}

	&__plain {
		padding-bottom: (425 / 375) * 100%;
	}
}

@include to($bp-small) {
	.l-fold {
		&--subtitle {
			width: 66%;
		}
		&--video-bg {
			display: none;
		}
	}
}

@include from($bp-small) {
	.l-fold {
		padding-bottom: (770 / 1920) * 100%;
		height: auto;

		&--inner {
			flex-direction: column;
			justify-content: flex-end;
		}

		&--copy {
			width: 62%;
		}

		&--supertitle {
			font-size: $fs-body-large;
		}
		&--title {
			line-height: 0.82;
			&-upper,
			&-lower {
			}
		}
		&--subtitle {
			font-size: $fs-h3;
			margin-top: $padding-rythm / 2;
		}

		&__plain {
			padding-bottom: (470 / 1920) * 100%;
		}
	}
}

@include from($bp-medium) {
	.l-fold {
		$c-root: &;

		&--inner {
			padding: $padding-rythm;
		}

		&--title {
			font-size: $fs-h1 * 0.7;
		}
		&--copy {
			width: 100%;
		}

		&__plain {
			min-height: 500px;
		}
	}
}

@include from($bp-large) {
	.l-fold {
		&--inner {
			padding: $padding-rythm-lg;
		}

		&--title {
			// font-size: 14.2vw; #tempthemeremove Put back in when theme is live -JS
			font-size: 8.2vw;
		}
	}
}

@include from(1680px) {
	.l-fold {
		&--title {
			font-size: $fs-h1;
		}
	}
}
