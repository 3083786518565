.heading-with-line {
	margin-bottom: $padding-rythm-sm;
	position: relative;

	&--text {
		max-width: 70%;
		padding-bottom: $padding-rythm-sm/1.5;
		margin-top: -0.2em; // Adjust for bad type setting -JS
	}

	&:after {
		content: "";
		width: 100%;
		height: 1px;
		background-color: var(--primary-dark);
		position: absolute;
		left: 0;
		bottom: 0;
	}

	@include from($bp-medium) {
		&--text {
			max-width: 90%;
		}
	}

	@include from($bp-very-large) {
		&--text {
			max-width: 70%;
		}
	}
}
