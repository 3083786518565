.l-copy-video {
}

@include from($bp-medium) {
	.l-copy-video {
		width: 80%;
		display: grid;
		grid-template-columns: 2fr 2fr;
	}
}
