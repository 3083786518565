.detail-list {
	&--item {
		margin-bottom: $padding-rythm-sm;
		padding-bottom: $padding-rythm-sm;
		position: relative;
		&:after {
			content: "";
			width: 100%;
			height: 1px;
			background-color: var(--primary-dark);
			position: absolute;
			left: 0;
			bottom: 0;
		}

		&-title {
			font-size: $fs-body;
		}
		&-value {
			font-size: $fs-body-large;
			&__small {
				text-transform: none;
			}
		}

		&:last-child {
			&:after {
				content: none;
			}
		}
	}
}

@include from($bp-medium) {
	.detail-list {
		&--item {
			&-title {
				font-size: $fs-body;
			}
			&-value {
			}
		}
	}
}
