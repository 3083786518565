$input-text-active-offset: 8px;
$input-padding: $padding-rythm-sm / 2;
$input-main-color: grey;
$input-required-color: red;

.form-textarea {
	margin-bottom: $padding-rythm;
	max-width: 100%;
	width: 100%;
	padding: $padding-rythm 0;
}

.form-input {
	position: relative;
	width: 100%;

	&--field,
	&--label {
		display: block;

		line-height: 1.3;
		background: none;
	}
	&--field {
		border: 0 none;
		padding: $input-padding;
		width: 100%;
		appearance: none;
		border-radius: 0;
		border: 1px solid $input-main-color;

		&:focus,
		&[value]:not([value=""]),
		&.is-focused {
			outline: none;

			&.is-valid {
			}

			&.is-invalid {
				border-color: $input-required-color;
			}
		}

		&.is-invalid {
			border-color: $input-required-color;
		}
		&.is-invalid {
			~ .form-input--error-messages {
				display: block;
			}
		}
		&:invalid {
			// Overide browser defaults. We use classes as below (for browser compat and UX reasons)
			outline: 0 none;
			box-shadow: none;
		}
	}
	&--label {
		margin-bottom: 12px;
		&-required {
			color: $input-required-color;
		}
	}

	&--error-messages {
		color: $input-required-color;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&--help {
		width: 100%;
		padding: 12px;
	}

	+ .form-input {
		margin-top: $padding-rythm-sm / 2;
	}
}

@include from($bp-medium) {
	.form-input {
		+ .form-input {
			margin-top: $padding-rythm / 2;
		}
	}
}
