.blockquote {
	padding-left: $padding-rythm-sm;
	border-left: 1px solid black;
	// border-left: 1px solid var(--text-accent); #tempthemeremove uncomment when theme goes live
	margin: 0;
	font-size: $fs-body-large;
	&--body {
	}
	&--citation {
		font-style: italic;
	}
}

@include from($bp-medium) {
	.blockquote {
		font-size: $fs-h3;
	}
}
