.l-streamfield {
	> * + * {
		margin-top: $padding-rythm-sm;
	}
	> .captioned-media,
	> .video-embed,
	> .blockquote {
		margin: $padding-rythm 0;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&--fullwidth-block {
		.card-group {
			margin: $padding-rythm 0;
		}
	}
}

@include from($bp-medium) {
	.l-streamfield {
		> * + * {
			margin-top: $padding-rythm;
		}
		> .captioned-media,
		> .video-embed,
		> .blockquote {
			margin: $padding-rythm-lg 0;
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		&--fullwidth-block {
			width: calc(100vw - #{$padding-rythm * 2});
			.card-group {
				margin: $padding-rythm-lg 0;
			}
		}

		&--floated-image {
			position: absolute;
		}
	}
}

@include from($bp-large) {
	.l-streamfield {
		&--fullwidth-block {
			width: calc(100vw - #{$padding-rythm-lg * 2});
		}
	}
}
