@include to($bp-medium) {
	.richtext-image {
		&--asset {
			margin-top: $padding-rythm-sm;
		}
	}
}

@include from($bp-medium) {
	.richtext-image {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: $padding-rythm;
		.rich-text {
			grid-column: 1/3;
		}
		&--asset {
			grid-column: 3/4;
		}
	}
}

@include from($bp-very-large) {
	.richtext-image {
		grid-gap: $padding-rythm-lg;
	}
}
