.l-site-footer {
	background-color: black;
	background-color: var(--primary-dark);

	color: white;
	color: var(--text-light);
	padding: $padding-rythm/2;
	padding-top: $padding-rythm;

	&--description {
	}
	&--links {
		margin-top: $padding-rythm;
		width: 100%;
		&-single {
			text-transform: uppercase;
			font-family: $body-font;
			font-weight: bold;

			margin: 0;

			@include base-ui-link;

			padding-bottom: $padding-rythm-sm/2;
			&:after {
				background: white;
			}
		}
		&-single + &-single {
			margin-top: $padding-rythm-sm;
		}
	}
	&--logos {
		display: flex;
		flex-flow: row nowrap;
		height: 50px;
		width: 100%;
		justify-content: space-between;
		margin: $padding-rythm-lg 0;
		&-single {
			& svg {
				height: 100%;
				width: auto;
			}
		}
	}

	&--sponsor {
		width: 100%;
		&-text {
			border-top: 1px solid white;

			padding-top: $padding-rythm-sm;
			margin-bottom: $padding-rythm-sm;
		}
		&-logo {
			max-width: 120px;
			svg {
				display: block;
			}
		}
	}
}

@include to($bp-medium) {
	.l-site-footer {
		&--logos {
			&-single {
				&:nth-child(1) {
					margin-right: auto;
				}
			}
		}
	}
}

@include from($bp-medium) {
	.l-site-footer {
		padding: $padding-rythm;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: auto auto;
		grid-column-gap: $padding-rythm-lg;
		justify-content: space-between;

		&--sponsor,
		&--links {
			grid-column: 5;
		}

		&--description {
			grid-column: 1/3;
		}

		// gap between bottom logos and top content should be relative to the nav links here - AS
		&--links {
			margin: 0 0 $padding-rythm-lg * 2 0;
		}

		&--logos {
			display: block;
			grid-column: 1 / span 4;
			margin: 0;
			align-self: end;
			justify-content: start;
			width: auto;
			&-single {
				height: 90px;
				display: inline-block;
			}
			&-single:nth-child(1) {
				margin-right: 90px;
			}
		}
	}
}

@include from($bp-large) {
	.l-site-footer {
		padding: $padding-rythm-lg;
	}
}
