@mixin block-base-sm() {
	background: white;
	padding: $padding-rythm-sm;
}

@mixin block-base() {
	background: white;
	padding: $padding-rythm;
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: $heading-font;
	margin: 0;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.2;
}

@mixin base-link() {
	color: inherit;
	display: inline-block;
	position: relative;
	border-bottom: 1px solid black;
	&:hover,
	&:focus,
	&:active {
		border-bottom-width: 2px;
	}
}

@mixin base-ui-link() {
	transition:
		border-color 200ms ease-in-out,
		border-width 200ms ease-in-out;
	position: relative;

	&:after {
		transition:
			background 200ms ease-in-out,
			transform 200ms ease-in-out;
		transform-origin: top left;

		content: "";
		width: 100%;
		height: 1px;
		bottom: 0;
		left: 0;
		background: white;
		background: var(--primary-light);
		display: block;
		position: absolute;
	}

	&:hover,
	&:active,
	&:focus {
		&:after {
			background: var(--text-light);
			transform: scaleY(2);
		}
	}
}
