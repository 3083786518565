.l-exhibition {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	aspect-ratio: 25 / 12;
	padding: $padding-rythm $padding-rythm / 2;
	padding-top: 0;
	padding-bottom: 0;
	border-width: 8px 0 0 0;
	border-color: var(--primary-dark);
	border-style: solid;

	&:last-child {
		border-bottom-width: 8px;
	}

	&--img img {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&--title {
		color: var(--text-light);
		line-height: 1;
		font-size: 45px;
	}

	&--link {
		text-transform: uppercase;
		font-weight: bold;
		color: var(--text-light);
		margin-top: 2rem;
	}
}

@include from($bp-tiny) {
	.l-exhibition .btn-group {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		.btn {
			padding-right: calc(10vw + 50px);
		}
	}
}

@include from($bp-small) {
	.l-exhibition {
		aspect-ratio: 48 / 13;

		.btn-group .btn {
			padding-right: calc(8vw + 40px);
		}
	}
}

@include from($bp-medium) {
	.l-exhibition {
		padding: $padding-rythm-lg / 2;

		&--title {
			line-height: 0.82;
			font-size: $fs-h2 * 1.7;
		}

		.btn-group .btn {
			padding-right: calc(5vw + 25px);
		}
	}
}

@include from($bp-large) {
	.l-exhibition {
		padding: $padding-rythm-lg;
	}
}
