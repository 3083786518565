// Unfortunately this component is necesarily crazy complex. It's all one massive complex component because cards
// and hover states rely on the grid spacing setup in the overall timeline. -JS

$cols: 5;
$gutter: $padding-rythm-lg;

// This calc is pretty nuts. Unfortunately I couldnt break it into variables because SASS would then
// omit brackets which are necesary. Basically what it does is produce a calc() expression that works
// out how big a column needs to be in vw units minus a value in pixels that represents the gutter.
// You can also add some extra spacing which is used to add full or half gutter amounts as needed
// to align bits of timeline components with eachother. -JS
@function tl-col($col-span, $add-gutter: false, $extra-spacing: 0px) {
	$add-gutter-result: if($add-gutter, var(--gutter), 0px);

	@return calc(
		(
				#{$col-span} * (
						(100vw - (var(--gutter) * 2)) / var(--cols) -
							(var(--gutter) * (var(--cols) - 1)) / var(--cols)
					) + (#{($col-span - 1)} * var(--gutter))
			) + #{$add-gutter-result} + #{$extra-spacing}
	);
}

@keyframes fade-in-from-half {
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out-in {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.l-timeline {
	$c-root: &;

	--gutter: #{$padding-rythm-sm};
	--cols: 2;

	display: block;
	overflow: hidden;

	padding-bottom: $padding-rythm; // Matches what's in l-page -JS

	background: var(--primary-light);

	&--title {
		font-size: $fs-h2;
		color: var(--text-accent);
	}

	// &--segment represents a section of the timeline that lays out cards - with offsets as set via data-level
	&--segment {
		$segment-root: &;

		position: relative;

		margin-left: $padding-rythm-sm;
		margin-top: $padding-rythm * 1.5;
		padding-left: $padding-rythm-sm;
		border-left: 1px solid var(--primary-dark);

		&[data-level="0"] {
			margin-top: $padding-rythm-lg;
		}
		&[data-level="1"] {
			margin-top: $padding-rythm-lg * 2;
		}
		&[data-level="2"] {
			margin-top: $padding-rythm-lg * 3;
		}
		&[data-level="3"] {
			margin-top: $padding-rythm-lg * 4;
		}
		&[data-level="4"] {
			margin-top: $padding-rythm-lg * 5;
		}

		&-header {
			width: calc(100% - var(--gutter));
			&-date,
			&-title {
				color: var(--text-accent);
				text-transform: uppercase;
			}
			&-date {
				margin-bottom: $padding-rythm-sm - 2px; // Adjusted for line-height
				font-weight: bold;
			}
			&-supertitle {
				text-transform: uppercase;
				font-weight: bold;
				margin-bottom: 3px; // Adjusted for line-height
			}
			&-title {
				margin-bottom: $padding-rythm - 10px; // Adjusted for line-height
			}
			&-image {
				margin-bottom: $padding-rythm;
			}
		}

		&-inner {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		&__small {
			width: tl-col(2);
		}

		&__full {
			width: tl-col(5);
		}
	}

	&--card {
		$card-root: &;

		position: relative;

		width: tl-col(1, false, -#{$padding-rythm-sm/2});
		color: black;
		color: var(--text-dark);
		margin-right: $padding-rythm-sm - 1px;
		&:last-child {
			margin-right: 0;
		}

		&-img {
			transition: 200ms ease-in-out;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			box-shadow: $box-shadow;
		}

		&-inner {
			padding-top: tl-col(1, false, -#{$padding-rythm-sm/2});
		}

		&-copy {
			margin-top: $padding-rythm-sm;
		}

		&-title {
			text-transform: uppercase;
			font-size: $fs-body;
			// color: $dark-grey; #tempthemeremove Put back in when theme is live -JS
			// color: var(--text-accent); #tempthemeremove Put back in when theme is live -JS
		}
		&-supertitle {
			text-transform: uppercase;
			font-size: $fs-body-tiny;
			font-weight: bold;
		}
		&-summary {
			font-size: $fs-body;
			margin-top: 15px; // Adjusted for line height
			.rich-text a {
				border-bottom-color: white;
			}
		}

		&__right {
			margin-top: tl-col(0.75);
		}

		&__simple-hover {
			#{$card-root}-summary {
				display: none;
			}
		}
	}
}

@include from($bp-small) {
	.l-timeline {
		--gutter: #{$padding-rythm-sm * 2};
		--cols: 3;
		&--card {
			min-height: tl-col(1, true);
			&-title {
				font-size: $fs-body-large;
			}
			&-supertitle {
				font-size: $fs-body-small;
			}
			&-summary {
				font-size: $fs-body-large;
			}

			&__right {
				margin-top: 0;
			}
		}
	}
}

@include to($bp-medium) {
	.l-timeline {
		&--segment {
			&-header {
				margin-bottom: $padding-rythm;
			}
		}
	}
}

@include from-to($bp-small, $bp-medium) {
	.l-timeline {
		&--card {
			&:nth-child(3n + 2) {
				margin-top: tl-col(0.5);
			}
			&:nth-child(3n + 3) {
				margin-top: tl-col(1);
			}
		}
	}
}

@include from($bp-medium) {
	.l-timeline {
		$c-root: &;

		--cols: 4;

		display: flex;
		flex-wrap: wrap;

		padding-bottom: $padding-rythm-lg;

		&--segment {
			$segment-root: &;

			padding-left: 0;

			margin-left: var(--gutter);
			padding-left: 0;
			border-left: 0 none;

			&-header {
				width: auto;
				&-date {
					padding-bottom: $padding-rythm-sm - 9px; // Adjusted for line-height
					margin-bottom: $padding-rythm-sm - 2px; // Adjusted for line-height
					border-bottom: 1px solid var(--primary-dark);
				}
			}

			&__artist-announcement {
				width: 100%;
				#{$segment-root}-header {
					width: tl-col(2);
					margin-bottom: calc(2 * var(--gutter));
				}
				#{$c-root}--card:nth-child(4n - 2) {
					margin-top: calc(-1 * var(--gutter));
				}
				#{$c-root}--card:nth-child(4n - 1),
				#{$c-root}--card:nth-child(4n) {
					margin-top: calc(-2 * var(--gutter));
				}
			}

			&__has-image {
				width: tl-col(4);
				#{$segment-root}-header {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					&-date {
						width: 100%;
					}
					&-image,
					&-intro {
						width: calc(50% - (var(--gutter) / 2));
					}
					&-image {
						margin-right: var(--gutter);
						box-shadow: $box-shadow;
					}
					&-intro {
						align-items: flex-start;
					}
				}
			}
		}

		&--card {
			$card-root: &;

			width: tl-col(1);
			margin-right: calc(
				var(--gutter) - 4px
			); // Gross hack because it needs to work NOW
			min-height: tl-col(1, true, $padding-rythm-sm * 2);
			&:nth-child(n) {
				margin-top: 0;
			}
			&:nth-child(1n + 5) {
				margin-top: var(--gutter);
			}

			&-inner {
				transition: opacity 300ms ease-in-out;
				width: tl-col(2);
				position: absolute;
				top: 0;
				left: 0;
				padding-top: tl-col(1, true, 10px);
				&:after {
					content: "";
					width: tl-col(2, true);
					height: calc(
						100% + (var(--gutter) / 2) + (var(--gutter) * 1.5)
					); // Last + $gutter is to account for scaling image size
					top: calc(-1 * (var(--gutter) / 2));
					left: calc(-1 * (var(--gutter) / 2));
					position: absolute;
					background: var(--primary-dark);
					z-index: -1;
					box-shadow: $box-shadow;
				}
			}

			&-copy {
				margin-top: 0;
			}

			&-inner:after,
			&-summary {
				transition: opacity 200ms ease-in-out;
				opacity: 0;
			}

			&-inner,
			&-inner:after,
			&-summary {
				pointer-events: none;
			}

			&-summary {
				margin-top: $padding-rythm-sm;
				.btn {
					font-size: $fs-body-tiny;
					padding-right: calc(4vw + #{$padding-rythm-sm});
				}
			}

			&-title,
			&-supertitle {
				pointer-events: all;
				transition: 0ms ease-in-out;
				transition-delay: 250ms;

				animation: fade-out-in 500ms forwards;

				&:first-child {
					margin-top: calc(-1 * var(--gutter));
				}
			}

			&-title,
			&-img,
			&-supertitle {
				width: tl-col(1);
			}

			&:hover {
				z-index: 1000;
				color: white;
				color: var(--text-light);
				#{$card-root}-img {
					width: tl-col(1, true);
				}
				#{$card-root}-inner:after {
					opacity: 1;
				}
				#{$card-root}-inner:after,
				#{$card-root}-summary {
					pointer-events: all;
				}
				#{$card-root}-summary,
				#{$card-root}-title,
				#{$card-root}-supertitle {
					animation: fade-in-from-half 500ms forwards;
					transform: translateY(var(--gutter));
				}
				.btn,
				#{$card-root}-summary,
				#{$card-root}-title,
				#{$card-root}-supertitle {
					color: white;
					color: var(--text-light);
				}
			}

			&__right {
				#{$card-root}-img,
				#{$card-root}-inner {
					left: auto;
					right: 0;
				}
				#{$card-root}-inner:after,
				#{$card-root}-summary {
					left: auto;
					right: calc(-1 * (var(--gutter) / 2));
				}
				#{$card-root}-title,
				#{$card-root}-supertitle {
					margin-left: tl-col(
						1,
						true
					); // Has to be a set margin not auto so the 0ms transition hack above works -JS
				}
				&:hover {
					#{$card-root}-title,
					#{$card-root}-supertitle {
						margin-left: 0;
					}
				}
			}

			&__simple-hover {
				#{$card-root}-inner {
					width: tl-col(1);
					&:after {
						width: tl-col(1, true);
						height: calc(100% + var(--gutter));
					}
				}

				#{$card-root}-copy {
					display: flex;
					flex-direction: column;
				}

				#{$card-root}-title,
				#{$card-root}-supertitle {
					animation: none;
					margin-left: 0;
				}

				#{$card-root}-summary {
					line-height: 1;
					display: block;
				}
				&:hover {
					#{$card-root}-img {
						width: tl-col(1);
					}
					#{$card-root}-summary,
					#{$card-root}-title,
					#{$card-root}-supertitle {
						animation: none;
						transform: translateY(0);
					}
					#{$card-root}-summary {
						animation: fade-in-from-half 500ms forwards;
					}
				}
			}
		}
	}
}

@include from($bp-large) {
	.l-timeline {
		$c-root: &;

		--cols: 5;
		--gutter: #{$padding-rythm-lg};

		&--segment {
			$segment-root: &;

			margin-top: $padding-rythm-lg;

			&__artist-announcement {
				#{$c-root}--card:nth-child(n) {
					margin-top: 0;
				}
				#{$c-root}--card:nth-child(5n - 2) {
					margin-top: calc(-1 * var(--gutter));
				}
				#{$c-root}--card:nth-child(5n - 1),
				#{$c-root}--card:nth-child(5n) {
					margin-top: calc(-2 * var(--gutter));
				}
			}
		}

		&--card {
			$card-root: &;

			min-height: tl-col(1, true, $padding-rythm-lg);
			&:nth-child(n) {
				margin-top: 0;
			}
			&:nth-child(1n + 6) {
				margin-top: var(--gutter);
			}

			&__simple-hover {
				#{$card-root}-summary {
					margin-top: auto;
				}
			}
		}
	}
}
