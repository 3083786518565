.submenu {
	position: relative;

	&--overlay {
		display: flex;
		flex-direction: column;
		gap: $padding-rythm-sm;
		padding: $padding-rythm-sm 15px;
	}

	&--item {
		text-decoration: underline;
		text-decoration-color: transparent;
		text-underline-offset: 5px;
		transition: text-decoration-color $base-anim-rythm ease-in-out;
		color: white;
		text-transform: uppercase;
		font-family: $body-font;
		font-weight: bold;

		&:hover {
			text-decoration-color: currentColor;
		}
	}

	@include from($bp-medium) {
		&--overlay {
			opacity: 0;
			transition: opacity $base-anim-rythm ease-in-out;
			pointer-events: none;
			position: absolute;
			border-bottom: 1px solid var(--text-light);
			top: calc(100% + 1px);
			left: 0;
			width: 100%;
			background: black;
		}
		&:hover {
			.submenu--overlay {
				opacity: 1;
				pointer-events: all;
				z-index: $zi-layer-4;
			}
		}
	}
}
