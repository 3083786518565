.video-embed {
	position: relative;

	display: block;
	width: 100%;

	box-shadow: 10px 30px -10px rgba(0, 0, 0, 0.5);

	&:only-child {
		margin-bottom: 0;
	}

	&--cover {
		transition: opacity 500ms 300ms ease-in-out;
		position: relative;
		display: block;
		//Important because of IE
		text-decoration: none !important;

		width: 100%;
		padding-bottom: #{(1080 / 1920) * 100%};
		display: block;
		height: 0;
		overflow: hidden;

		&.has-video {
			opacity: 0;
		}

		&:after {
			content: "";

			position: absolute;
			top: 50%;
			left: 50%;

			width: 0;
			height: 0;
			border-top: 25px solid transparent;
			border-bottom: 25px solid transparent;

			border-left: 50px solid white;

			text-decoration: none;

			transition: 300ms ease-in-out;
			transform: translate(-50%, -50%) translateZ(0);

			opacity: 0.7;

			z-index: 100;
		}
		&:hover {
			&:after {
				transform: translate(-50%, -50%) translateZ(0);
				opacity: 1;
			}
		}
	}

	&--cover-image,
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&--cover-image {
		display: block;

		transition: 300ms ease-in-out;
		object-fit: cover;

		transform: translate3d(0, 0, 0);
	}

	iframe {
		z-index: 20;
	}
}

@include from($bp-medium) {
	.video-embed {
		&--cover {
			&:after {
				border-top: 40px solid transparent;
				border-bottom: 40px solid transparent;

				border-left: 80px solid white;
			}
		}
	}
}
