.l-page {
	padding: $padding-rythm $padding-rythm/2;

	.l-textual-layout + .l-textual-layout {
		margin-top: $padding-rythm-lg;
	}

	&__d-background {
		background: var(--primary-dark);
		color: var(--text-light);
	}
	&__l-background {
		background: var(--primary-light);
		color: var(--text-dark);
	}

	&__archive {
		padding: 0;
		flex-direction: column;
	}
}

@include to($bp-medium) {
	.l-page {
		&--aside {
			margin-top: $padding-rythm;
		}
	}
}

@include from($bp-medium) {
	.l-page {
		$c: &;
		padding: $padding-rythm;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		column-gap: $padding-rythm;

		&--main {
			grid-column: 1/4;
			&__full {
				grid-column: 1/6;
			}
		}
		&--aside {
			grid-column: 4/6;
		}

		&__single-col {
			grid-template-columns: repeat(3, 1fr);
			#{$c}--main {
				grid-column: 1/3;
			}
			#{$c}--aside {
				grid-column: 3/4;
			}
		}

		&__archive {
			padding: 0;
			flex-direction: column;
		}
	}
}

@include from($bp-large) {
	.l-page {
		padding: $padding-rythm-lg;
		column-gap: $padding-rythm-lg;

		&__archive {
			padding: 0;
			flex-direction: column;
		}
	}
}
