.btn {
	font-weight: bold;
	text-transform: uppercase;
	color: var(--text-accent);
	font-size: $fs-body-small;
	position: relative;
	padding-right: 90px;
	min-height: 23px;

	display: flex;
	align-items: center;

	background: none;
	border: 0 none;

	&:after,
	&:before {
		content: "";
		position: absolute;
	}

	&:before {
		transition: transform 150ms ease-in;
		transform-origin: center left;

		width: 80px;
		height: 1px;
		background: var(--text-accent);
		top: 11px;
		right: 2px;
	}

	&:after {
		transition: transform 150ms ease-in;

		width: 16px;
		height: 16px;
		border-top: 1px solid var(--text-accent);
		border-right: 1px solid var(--text-accent);
		top: 11px;
		right: 4px;
		margin-top: -8px;
		transform: rotate(45deg);
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			transform: scaleX(1.2);
		}
		&:after {
			transform: translateX(calc(5vw * 0.2)) rotate(45deg);
		}
	}
}

.btn-group {
	.btn + .btn {
		margin-top: $padding-rythm-sm;
	}
}

@include from($bp-medium) {
	.btn {
		padding-right: calc(5vw + #{$padding-rythm-sm});
		&:before {
			width: 5vw;
			right: 0;
		}
		&:after {
			right: 2px;
		}
	}
	.btn-group {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		.btn + .btn {
			margin-top: $padding-rythm;
		}
	}
}
