html,
body {
	width: 100%;
	overflow-x: hidden;
}

body {
	font-size: 16px;
	font-family: $body-font;
	line-height: 1.4;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.preload {
		* {
			transition: none !important;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

h1 {
	font-size: $fs-h1-sm;
}

h2 {
	font-size: $fs-h2-sm;
}

h3 {
	font-size: $fs-h3-sm;
}

.lede {
	font-size: 1.2em;
}

.richtext,
.rich-text {
	font-size: $fs-body;
	line-height: 1.5;
	> * + * {
		margin-top: 0.4em;
	}

	> p + p {
		margin-top: 1em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	> * + h2,
	> * + h3 {
		margin-top: 2em;
	}

	h1,
	h2,
	h3,
	h4 {
		color: black;
		color: var(--text-accent);
	}

	a:not([class]) {
		@include base-link;
		display: inline;
	}

	ul,
	ol {
		padding-left: 1em;
	}

	ul {
		li {
			list-style: disc;
		}
	}

	table {
		tr {
			border-bottom: 1px solid;
			&:last-child {
				border-bottom: 0 none;
			}
		}
		th,
		td {
			vertical-align: top;
			text-align: left;
		}
		th {
			font-weight: normal;
			padding-bottom: $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm;
		}
		td {
			padding-top: $padding-rythm-sm / 4;
			padding-bottom: $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm;
		}
	}

	.responsive-object {
		position: relative;
		iframe,
		img {
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}
}

@include from($bp-medium) {
	h1 {
		font-size: $fs-h1;
	}

	h2 {
		font-size: $fs-h2;
	}

	h3 {
		font-size: $fs-h3;
	}

	.richtext,
	.rich-text {
		font-size: $fs-body-large;
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}
	}
}
