.social-bar {
	list-style: none;
	padding: 0;
	margin: 0;
	&--item {
		display: inline-block;
		margin-right: 20px;
	}
	&--link {
		display: block;
		width: 40px;
		height: 40px;
		border: 3px solid black;
		color: black;
		padding: 5px;
		svg {
			width: 100%;
			height: auto;
			display: block;
		}
	}
}
