.captioned-media {
	&--asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&--caption {
		padding-top: $padding-rythm-sm;
	}
}

@include from($bp-medium) {
	.captioned-media {
		&--caption {
			font-size: $fs-body-large;
			padding-top: $padding-rythm / 2;
		}
	}

	.captioned-media-group {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: $padding-rythm;
	}
}

@include from($bp-very-large) {
	.captioned-media-group {
		grid-gap: $padding-rythm-lg;
	}
}
