.l-site-header {
	padding: $padding-rythm-sm;
	z-index: $zi-layer-nav;
	position: relative;
	background-color: var(--primary-dark);
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	&--logo {
		width: 99px;
		&-img {
			width: 100%;
			height: auto;

			> svg {
				color: var(--text-light);
			}
		}

		.has-logo-fold & {
			opacity: 0;
		}
	}

	&--menu-trigger {
		z-index: $zi-layer-nav + 1;
	}

	&--navigation {
		transition: transform 300ms ease-in-out;
		width: 100vw;
		height: 100vh;
		background: black;
		position: fixed;
		top: 0;
		left: 0;
		transform: translateX(100%);
		display: flex;
		flex-direction: column;

		&-item {
			text-transform: uppercase;
			font-family: $body-font;
			font-weight: bold;
			margin: 0;

			color: white;

			@include base-ui-link;

			&:after {
				background: white;
				width: 100%;
			}
		}
	}
}

@include to($bp-medium) {
	.l-site-header {
		align-items: center;
		&--navigation {
			padding: $padding-rythm-sm;
			padding-top: $padding-rythm-lg;
			overflow-y: scroll;
			gap: $padding-rythm-sm;
			&.is-active {
				transform: translateX(0);
			}
		}
	}
}

@include from($bp-medium) {
	.l-site-header {
		padding: $padding-rythm;

		&--logo {
			width: 188px;
			z-index: 1;
		}

		&--menu-trigger {
			display: none;
		}

		&--navigation {
			position: relative;
			transform: translateY(#{-$padding-rythm-sm / 2}) translateX(0);
			width: auto;
			height: auto;
			flex-direction: row;
			background: none;
			display: grid;
			grid-auto-columns: 1fr;
			grid-auto-flow: column;
			gap: $padding-rythm;
			min-width: 50%;

			&-item {
				padding-bottom: $padding-rythm-sm / 2;
			}
		}
	}
}

@include from($bp-large) {
	.l-site-header {
		padding: $padding-rythm $padding-rythm-lg;
		&--logo {
			left: $padding-rythm-lg;
		}
	}
}
