/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-tiny: 400px;
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1280px;
$bp-very-large: 1441px;
$bp-ultra-large: 1920px;

/**
 * Font definitions
 */
$defaultFontSize: 1;

$body-font: "brandon-grotesque", sans-serif;
$heading-font: "brandon-grotesque", sans-serif;

/**
 * Font Sizes
 */
$fs-h1: 175px;
$fs-h2: 50px;
$fs-h3: 30px;
$fs-h1-sm: 70px;
$fs-h2-sm: 22px;
$fs-h3-sm: 22px;
$fs-body-large: 22px;
$fs-body: 18px;
$fs-body-small: 16px;
$fs-body-tiny: 14px;

/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Padding
 * Usage: padding: $padding-rythm*2
 */
$padding-rythm-lg: 100px;
$padding-rythm: 50px;
$padding-rythm-sm: 25px;

/**
 * Raw Colors
 */
$dark-grey: #595959;
$middle-grey: #808285; // #a7a9ac - put the similar colors you've replaced here
$midlight-grey: #d8d8d8;
$light-grey: #e6e6e6; // #eaebec

/**
 * z-index stack - where possible use DOM order or small hoists (eg z-index: 1 vs z-index: $zi-layer-4)
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;

/**
 * Misc
 */
$max-width: ($bp-medium * 1px);
$box-shadow: 0 10px 30px -10px rgba(black, 0.5);

/**
	* Theme variables
	*
	* Each exhibition page has it's own theme colours that can be specified by Hobart Current.
	* The default theme is the black and white theme.
	* Using CSS4 declarations, which isn't supported in IE atm..
	*/
:root {
	/* background colour used on the footer, intro sections (after fold), card hover */
	--primary-dark: black;

	/* background colour used to differentiate components and hover states in timeline and stream field content section */
	--primary-medium: #808285;

	/* background colour used on timeline and stream field content section */
	--primary-light: white;

	/* used for body text, and post tags (e.g. director update tag) */
	--text-dark: black;

	/* used on the feature headings, cta links, fold text, card headings */
	--text-accent: black;

	/* used on footer text, header links, fold text, intro sections*/
	--text-light: white;
}
