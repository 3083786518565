$control-size: 1em;

.form-boolean {
	input {
		display: none;
	}
	&--label {
		position: relative;
		padding-left: 1.2 * $control-size;
		&:before,
		&:after {
			content: "";
			width: $control-size;
			height: $control-size;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -#{$control-size/2};
		}
		&:before {
			border: 1px solid;
		}
		&:after {
			transition: transform 200ms ease-in-out;
			background: black;
			transform: scale(0);
		}
		&:hover {
			&:after {
				transform: scale(0.5);
			}
		}
	}
	input[type="radio"] + &--label {
		&:before,
		&:after {
			border-radius: 100%;
		}
	}
	input:checked + &--label {
		&:after {
			transform: scale(0.7);
		}
	}
}

.form-boolean-group {
	&--label {
		display: block;
		margin-bottom: 12px;
	}
	.form-boolean {
		+ .form-boolean {
			margin-top: $padding-rythm-sm;
		}
	}
}
