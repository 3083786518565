.l-grid {
	display: grid;
	grid-gap: $padding-rythm-lg;

	&--item {
	}

	&__2-1x {
	}

	@include from($bp-small) {
		display: grid;

		&__2-1x {
			grid-template-columns: 2fr 1fr;
			grid-template-rows: auto;
		}
	}
}
